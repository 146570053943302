<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Добавление</div>
                <div class="page__desc">Введите продукты, которые необходимо купить</div>
            </div>
        </div>

        <div class="page__content">
            <form class="form-group" method="POST" action="" @submit.prevent="create()">

                <label for="store_id">Магазин</label>
                <div v-if="stores" class="control-group">
                    <select
                            v-model="store_id"
                            id="store_id"
                            class="form-control"
                            required>
                        <option
                                v-for="store in stores"
                                v-bind:key="store.id"
                                :value="store.id">{{ store.address }}</option>
                    </select>
                </div>
                <br>

                <label for="product_id">Продукт</label>
                <div v-if="products" class="control-group">
                    <select
                            v-model="product_id"
                            id="product_id"
                            class="form-control"
                            required>

                        <template
                                v-for="cc in products"
                                :label="cc['title']">

                            <optgroup
                                    v-bind:key="cc['id']"
                                    :label="cc['title']"></optgroup>

                            <optgroup
                                    v-for="c in cc['categories']"
                                    v-bind:key="c['id']"
                                    :label="'↳' + c['title']">

                                <option
                                        v-for="p in c['products']"
                                        v-bind:key="p['id']"
                                        :value="p['id']">{{ p['title'] }}</option>
                            </optgroup>
                        </template>
                    </select>
                </div>
                <br>

                <label for="quantity_ordered">Количество</label>
                <div class="control-group">
                    <div class="input-group">
                        <input
                                v-model="quantity_ordered"
                                id="quantity_ordered"
                                type="number"
                                class="form-control"
                                required>
                        <div v-if="product != null" class="input-group-append">
                            <span class="input-group-text">{{ product.packaging_type }}</span>
                        </div>
                    </div>
                </div>
                <br>

                <label for="expected_at">Дата ожидания</label>
                <div class="control-group">
                    <input
                            v-model="expected_at"
                            id="expected_at"
                            type="datetime-local"
                            class="form-control"
                            required>
                </div>
                <br>

                <input type="submit" class="btn btn-success btn-large" value="Сохранить">

            </form>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'StoreSuppliesPlanCreate',
        data() {
            return {
                store_id: null,
                product_id: null,
                quantity_ordered: null,
                expected_at: null
            }
        },
        computed: {
            products() {
                return this.$store.state.promotions.promotionsProducts
            },
            stores() {
                return this.$store.state.stores.stores
            },
            product() {
                if (this.products != null && this.product_id != null) {
                    for (let i = 0; i < this.products.length; i++) {
                        for (let j = 0; j < this.products[i].categories.length; j++) {
                            for (let k = 0; k < this.products[i]['categories'][j]['products'].length; k++) {
                                if (this.product_id === this.products[i]['categories'][j]['products'][k]['id']) {
                                    return this.products[i]['categories'][j]['products'][k];
                                }
                            }
                        }
                    }
                }
                return null;
            }
        },
        methods: {
            ...mapActions([
                'storeStores',
                'storeSuppliesPlanCreate',
                'catalogPromotionsProducts',
            ]),
            async getStores() {
                await this.storeStores()
            },
            async getProducts() {
                await this.catalogPromotionsProducts()
            },
            async create() {
                await this.storeSuppliesPlanCreate({
                    store_id: this.store_id,
                    product_id: this.product_id,
                    quantity_ordered: this.quantity_ordered,
                    expected_at: this.$moment(this.expected_at).unix()
                })
            }
        },
        created() {
            this.getStores();
            this.getProducts();
        }
    }
</script>